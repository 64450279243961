import React, { useMemo, useEffect, useState, useContext } from 'react';
import {
    Grid,
    Typography,
    Button,
    FormHelperText,
    Divider,
    TextField,
    InputAdornment,
    IconButton,
    Collapse, Select as MSelect, MenuItem, Link, Box, Switch, FormControlLabel
} from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import {useTranslation} from "react-i18next";
import {
    deleteBill,
    getHomeRepairServices, getPaymentPlanByService,
    getSelectedHomeRepairServices,
    register,
    selectHomeRepairServices,
    updateProvider, deleteSelectedService
} from "../../api/api";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {Formik} from "formik";
import * as Yup from "yup";
import {IconEye, IconEyeOff} from "@tabler/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AWS from "aws-sdk";
import DropZone from "../../ui-component/DropZone";
import NewMap from "../Authentication/Maps/NewMaps";
const SelectedSkill = () => {
    let current = JSON.parse(localStorage.getItem('auth_user'));
    const app_settings = JSON.parse(localStorage.getItem('settings'));
    AWS.config.update({
        accessKeyId: app_settings.aws_access_key,
        secretAccessKey: app_settings.aws_secret_key,
        region: app_settings.aws_region
    });

    const toastOptions = useContext(ToastContext);
    const [isLoading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [homeRepairServices, setHomeRepairServices] = useState([]);
    const [selectedHomeRepairServices, setSelectedHomeRepairServices] = useState();
    const [service, setService] = useState();
    const [checked, setChecked] = React.useState(false);
    const [ButtonLoading, setButtonLoading] = React.useState(false);
    const [serviceArr, setServiceArr] = React.useState([]);
    const [subServiceArr, setSubServiceArr] = React.useState([]);
    const [deleteId, setDeleteId] = React.useState();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [subDeleteId, setSubDeleteId] = React.useState();
    const [subDeleteOpen, setSubDeleteOpen] = React.useState(false);
    const [attachment, setAttachment] = useState();
    const [isFileAttached, setFileAttached] = useState(true);
    const [LocationData, setLocationData] = useState('');


    useEffect(() => {
        const formData = {
            company_id: current?.company_id,
            user_id: current?._id,
            platform: process.env.REACT_APP_PLATFORM
        };
        getHomeRepairServices(formData).then((res) => {
            let home_services = res.data.data;
            setHomeRepairServices(home_services);

            getSelectedHomeRepairServices(formData).then((res) => {
                setSelectedHomeRepairServices(res.data.data);
                let serArr = [];
                let subSerArr = [];
                res.data.data.service_arr.map((e) => {

                    let service = home_services.find((j) => j._id === e.service_id)

                    if(e.sub_service_arr.length > 0){
                        e.sub_service_arr.map((j) => {
                            subSerArr.push(j.sub_service_id)
                        });
                        if(service.sub_service_arr.length === e.sub_service_arr.length){
                            serArr.push(e.service_id);
                        }
                    }
                    else{
                        serArr.push(e.service_id);
                    }
                });
                setServiceArr(serArr);
                setSubServiceArr(subSerArr);
                setLoading(false);
            });
        });



    }, []);

    const handleChange = (pService) => {
        setService(pService);

        if(service && service.service_id === pService.service_id){
            setChecked((prev) => !prev);
        }
        else if(pService.sub_service_arr.length === 0){
            setChecked((prev) => false);
        }
        else {
            setChecked(true);
        }

    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteId();
    }

    const handleSubDeleteClose = () => {
        setSubDeleteOpen(false);
        setSubDeleteId();
        setDeleteId();
    }

    const handleAttachmentUpload = async (file) => {
        setAttachment(file);
        setFileAttached(true)

    };

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) :
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12}>
                                    <Typography variant="h3">{t('Selected Services')}</Typography>
                                </Grid>
                                <Grid container spacing={2} sx={{p:3}}>
                                    {
                                        selectedHomeRepairServices?.service_arr.map((e) => (
                                                <Grid key={e.service_id} item lg={3} md={3} sm={3} xs={6}>
                                                    <Badge
                                                        badgeContent={
                                                            'x'
                                                        }
                                                        color="error"
                                                        onClick={() => {
                                                            setDeleteId(e.service_id);
                                                            setSubDeleteId();
                                                            setDeleteOpen(true);
                                                        }}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                    </Badge>
                                                    <Card sx={{cursor: 'pointer',height: '150px' }} elevation={3} onClick={() => handleChange(e)}>
                                                        <CardContent sx={{textAlign: 'center'}}>
                                                            <img src={e.service_image} height={'150px'} />
                                                        </CardContent>
                                                    </Card>
                                                    <Typography sx={{textAlign: 'center', mt: 1}}>{i18n.language==='ar'?e.service_name_ar:e.service_name_en}</Typography>
                                                </Grid>
                                        ))
                                    }
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Collapse in={checked}>
                                            <Typography variant="h3" sx={{mt: 3}}>{t('Selected Sub Services')}</Typography>
                                            <Grid container spacing={2} sx={{p:3}}>
                                                {
                                                    service?.sub_service_arr.map((j) => (
                                                        <Grid key={j.sub_service_id} item lg={3} md={3} sm={3} xs={6}>
                                                            <Badge
                                                                badgeContent={
                                                                    'x'
                                                                }
                                                                color="error"
                                                                onClick={() => {
                                                                    setDeleteId(j.service_id);
                                                                    setSubDeleteId(j.sub_service_id);
                                                                    setSubDeleteOpen(true);
                                                                }}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                            </Badge>
                                                            <Card sx={{height: '150px' }} elevation={3}>
                                                                <CardContent sx={{textAlign: 'center'}}>
                                                                    <img src={j.sub_service_image} height={'150px'}  />
                                                                </CardContent>
                                                            </Card>
                                                            <Typography sx={{textAlign: 'center', mt: 1}}>{i18n.language==='ar'?j.sub_service_name_ar:j.sub_service_name_en}</Typography>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Collapse>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                }
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (

                    <Formik initialValues={{
                        user_id: current?._id,
                        id_iqama: current?.id_iqama,
                        id_iqama_file: current?.id_iqama_file,
                        service_id_arr: [],
                        sub_service_id_arr: [],
                        platform: process.env.REACT_APP_PLATFORM,
                        desc: selectedHomeRepairServices?.desc,
                        latitude: parseFloat(selectedHomeRepairServices?.start_service_location?.latitude),
                        longitude: parseFloat(selectedHomeRepairServices?.start_service_location?.longitude),
                        address: selectedHomeRepairServices?.start_service_location?.address,
                        service_status: selectedHomeRepairServices.service_status === 'ACTIVE',
                        whatsapp_number: selectedHomeRepairServices.whatsapp_number?.replace('0',''),

                    }}
                            validationSchema={Yup.object().shape({
                                id_iqama: Yup.string().max(255).required(t('National Id/Iqama is required')),
                                whatsapp_number: Yup.string().nullable().matches(/^[0-9]+$/, "Must be only digits").test("Check prefix", t('Phone no. must start with 5'), function () {
                                    let phone = this.parent["whatsapp_number"];
                                    if(phone){
                                        if(phone[0] !== 5){
                                            return phone.startsWith(5);
                                        }
                                    }
                                    else{
                                        return true
                                    }
                                }).max(9).min(9)
                                // service_id_arr: Yup.array()
                                //     .of(Yup.string()).min(1, t('Service is required')).required(t('Service is required')),
                            })}
                            onSubmit={async (values) => {
                                if(values.service_status){
                                    values.service_status = 'ACTIVE'
                                }
                                else{
                                    values.service_status = 'INACTIVE'
                                }
                                if(LocationData){
                                    values.latitude = LocationData.lat;
                                    values.longitude = LocationData.lng;
                                    values.address = LocationData.address;
                                }
                                else{
                                    toast.error(t('Location in required'));
                                }
                                setButtonLoading(true);
                                if (!attachment && !values.id_iqama_file) {
                                    setFileAttached(false)
                                    setButtonLoading(false);
                                } else {
                                    if(!values.id_iqama_file) {
                                        try {
                                            const s3 = new AWS.S3();
                                            let extension = attachment[0].name.split('.').pop();
                                            const params = {
                                                ACL: 'public-read',
                                                Bucket: app_settings.aws_bucket_name,
                                                Key: `omran/id_iqama/${Date.parse(new Date())}.${extension}`,
                                                Body: attachment[0]
                                            };

                                            const response = await s3.upload(params).promise();
                                            values.id_iqama_file = response.Location;
                                            setFileAttached(true);
                                            setAttachment();

                                        } catch (error) {
                                            console.error('Error uploading file:', error);
                                        }
                                    }
                                }

                                if (values.id_iqama_file) {
                                    selectHomeRepairServices(values)
                                        .then((res) => {
                                            const response = res.data;
                                            setButtonLoading(false);
                                            if (response.code === 1) {

                                                localStorage.setItem('auth_user', JSON.stringify(response.data.user));
                                                current = response.data.user;
                                                setSelectedHomeRepairServices(response.data.selected_skill);
                                                values.service_id_arr = [];
                                                values.sub_service_id_arr = [];

                                                let serArr = [];
                                                let subSerArr = [];
                                                response.data.selected_skill.service_arr.map((e) => {

                                                    let service = homeRepairServices.find((j) => j._id === e.service_id)

                                                    if (e.sub_service_arr.length > 0) {
                                                        e.sub_service_arr.map((j) => {
                                                            subSerArr.push(j.sub_service_id)
                                                        });
                                                        if (service.sub_service_arr.length === e.sub_service_arr.length) {
                                                            serArr.push(e.service_id);
                                                        }
                                                    } else {
                                                        serArr.push(e.service_id);
                                                    }
                                                });

                                                setServiceArr(serArr);
                                                setSubServiceArr(subSerArr);
                                                setChecked(false);
                                                toast.success(i18n.language === 'ar' ? response.ar_message : response.en_message, toastOptions);
                                            } else {
                                                toast.error(i18n.language === 'ar' ? response.ar_message : response.en_message, toastOptions);
                                            }

                                        })
                                        .catch((err) => {
                                            if (err?.response?.status === 401) {
                                                setButtonLoading(false);
                                                toast.error(i18n.language === 'ar' ? err.response?.data?.ar_message : err.response?.data?.en_message, toastOptions);
                                            }
                                        });
                                }
                            }}>
                        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2} sx={{mt: 2}}>
                                            <Grid item lg={12} md={12}>
                                                <Typography variant="h4">{t('Update Skills')}</Typography>
                                                <Divider sx={{ mt: 2 }} />
                                            </Grid>

                                            <Grid item lg={6} md={6} xs={12}>
                                                <Typography sx={{ mb: 1 }}>{t('National Id/Iqama No.')}</Typography>
                                                <TextField
                                                    placeholder={t('National Id/Iqama No.')}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name="id_iqama"
                                                    value={values.id_iqama}
                                                    error={Boolean(touched.id_iqama && errors.id_iqama)}
                                                />
                                                {touched.id_iqama && errors.id_iqama && (
                                                    <FormHelperText error id="standard-weight-helper-text-id_iqama-login">
                                                        {errors.id_iqama}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12}>
                                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <Typography sx={{ mb: 1 }}>{t('National Id/Iqama')}</Typography>
                                                    {
                                                        current?.id_iqama_file && (
                                                            <Link href={current?.id_iqama_file} target="_blank">{t('View')}</Link>
                                                        )
                                                    }
                                                </Box>
                                                <DropZone key={1} onChange={handleAttachmentUpload} limit={1} />
                                                {
                                                    !isFileAttached?(
                                                        <FormHelperText error>
                                                            {t('Attachment is required')}
                                                        </FormHelperText>
                                                    ):(
                                                        <></>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Whatsapp Number')}</Typography>
                                                <TextField
                                                    placeholder={t('Whatsapp Number')}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography>+966</Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={handleChange}
                                                    name="whatsapp_number"
                                                    value={values.whatsapp_number}
                                                    error={Boolean(touched.whatsapp_number && errors.whatsapp_number)}
                                                />
                                                {touched.whatsapp_number && errors.whatsapp_number && (
                                                    <FormHelperText error id="standard-weight-helper-text-whatsapp_number-login">
                                                        {errors.whatsapp_number}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={4}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography sx={{ mb: 1 }}>{t('Select Service')}</Typography>
                                                <MSelect
                                                    fullWidth
                                                    multiple
                                                    name="service_id_arr"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.service_id_arr}
                                                    error={Boolean(touched.service_id_arr && errors.service_id_arr)}
                                                    placeholder={t('Select Services')}
                                                >
                                                    {
                                                        homeRepairServices.filter((j) => !serviceArr.includes(j._id))?.map((e) => (
                                                            <MenuItem key={e._id} value={e._id}>{i18n.language==='ar'?e.name_ar:e.name_en}</MenuItem>
                                                        ))
                                                    }
                                                </MSelect>
                                                {touched.service_id_arr && errors.service_id_arr && (
                                                    <FormHelperText error id="standard-weight-helper-text-service_id_arr-login">
                                                        {errors.service_id_arr}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            {values.service_id_arr.length > 0 &&
                                            homeRepairServices.filter((e) => values.service_id_arr.includes(e._id))?.find((e) => e.sub_service_arr && e.sub_service_arr.length > 0)? (
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Typography sx={{ mb: 1 }}>{t('Select Sub Services')}</Typography>
                                                    <MSelect
                                                        fullWidth
                                                        multiple
                                                        name="sub_service_id_arr"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.sub_service_id_arr}
                                                        error={Boolean(touched.sub_service_id_arr && errors.sub_service_id_arr)}
                                                        placeholder={t('Select Sub Services')}
                                                    >
                                                        {
                                                            homeRepairServices.filter((e) => values.service_id_arr.includes(e._id))?.map((e) => {
                                                                return (
                                                                    e.sub_service_arr.filter((j) => !subServiceArr.includes(j._id))?.map((sub) => (
                                                                        <MenuItem key={sub._id} value={sub._id}>{i18n.language==='ar'?sub.name_ar:sub.name_en}</MenuItem>
                                                                    ))
                                                                )
                                                            })
                                                        }
                                                    </MSelect>
                                                    {touched.sub_service_id_arr && errors.sub_service_id_arr && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-sub_service_id_arr-login"
                                                        >
                                                            {errors.sub_category}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            ) : (
                                                ''
                                            )}
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Coverage Area')}<Typography variant="body" sx={{color: 'red'}}>
                                                    *
                                                    <FormControlLabel
                                                        value={values.service_status}
                                                        onChange={handleChange}
                                                        name="service_status"
                                                        control={
                                                            values.service_status===true ?(<Switch defaultChecked color="success" />):(<Switch  color="success" />)
                                                        }
                                                        label={t('Coverage Status')}
                                                        labelPlacement='start'
                                                    />
                                                </Typography></Typography>
                                                <NewMap oldAddress={values.address}
                                                        center={{lat: values.latitude, lng: values.longitude}} zoom={15} onChange={setLocationData} />
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12}>
                                                <Typography sx={{ mb: 1 }}>{t('About')}</Typography>
                                                <TextField
                                                    placeholder={t('About')}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name="desc"
                                                    value={values.desc}
                                                    error={Boolean(touched.desc && errors.desc)}
                                                />
                                                {touched.desc && errors.desc && (
                                                    <FormHelperText error id="standard-weight-helper-text-desc-login">
                                                        {errors.desc}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    loading={ButtonLoading}
                                                    sx={{mt: 1}}
                                                    loadingPosition="center"
                                                >
                                                    {t('Save Service')}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
                )}
                {
                    deleteId && (
                        <Dialog open={deleteOpen} onClose={handleDeleteClose} fullWidth maxWidth="xs"  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title" variant="f4">
                                {t('Delete')}
                            </DialogTitle>

                            <DialogContent>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="nowrap"
                                >
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Typography>{t('Are you sure you want to delete?')}</Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDeleteClose} variant="outlined" color="error">
                                    {t('Cancel')}
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={ButtonLoading}
                                    loadingPosition="center"
                                    onClick={() =>
                                    {
                                        const formData = {
                                            service_id: deleteId,
                                            user_id: current?._id,
                                        };
                                        setButtonLoading(true);
                                        deleteSelectedService(formData)
                                            .then((res) => {
                                                const response = res.data;
                                                setButtonLoading(false);
                                                if(response.code===1){
                                                    setSelectedHomeRepairServices(res.data.data);
                                                    let serArr = [];
                                                    let subSerArr = [];
                                                    res.data.data.service_arr.map((e) => {
                                                        serArr.push(e.service_id);
                                                        e.sub_service_arr.map((j) => {
                                                            subSerArr.push(j.sub_service_id)
                                                        })
                                                    });
                                                    setServiceArr(serArr);
                                                    setSubServiceArr(subSerArr);
                                                    setButtonLoading(false);
                                                    handleDeleteClose();
                                                    toast.success(i18n.language==='ar'?response.ar_message:response.ar_message, toastOptions);
                                                }
                                                else{
                                                    toast.error(i18n.language==='ar'?response.ar_message:response.ar_message, toastOptions);
                                                }
                                            })
                                            .catch((err) => {
                                                setButtonLoading(false);
                                                if (err?.response?.status === 401) {
                                                    toast.error(err.response?.data?.error, toastOptions);
                                                }
                                            })
                                    }
                                    }
                                >
                                    {t('Delete')}
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    )
                }
                {
                    subDeleteId && (
                        <Dialog open={subDeleteOpen} onClose={handleSubDeleteClose} fullWidth maxWidth="xs"  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title" variant="f4">
                                {t('Delete')}
                            </DialogTitle>

                            <DialogContent>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="nowrap"
                                >
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Typography>{t('Are you sure you want to delete?')}</Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSubDeleteClose} variant="outlined" color="error">
                                    {t('Cancel')}
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={ButtonLoading}
                                    loadingPosition="center"
                                    onClick={() =>
                                    {
                                        const formData = {
                                            service_id: deleteId,
                                            sub_service_id: subDeleteId,
                                            user_id: current?._id,
                                        };
                                        setButtonLoading(true);
                                        deleteSelectedService(formData)
                                            .then((res) => {
                                                const response = res.data;
                                                setButtonLoading(false);
                                                if(response.code===1){
                                                    setSelectedHomeRepairServices(res.data.data);
                                                    let serArr = [];
                                                    let subSerArr = [];
                                                    res.data.data.service_arr.map((e) => {
                                                        serArr.push(e.service_id);
                                                        e.sub_service_arr.map((j) => {
                                                            subSerArr.push(j.sub_service_id)
                                                        })
                                                    });
                                                    setServiceArr(serArr);
                                                    setSubServiceArr(subSerArr);
                                                    setButtonLoading(false);
                                                    handleSubDeleteClose();
                                                    setChecked(false);
                                                    toast.success(i18n.language==='ar'?response.ar_message:response.ar_message, toastOptions);
                                                }
                                                else{
                                                    toast.error(i18n.language==='ar'?response.ar_message:response.ar_message, toastOptions);
                                                }
                                            })
                                            .catch((err) => {
                                                setButtonLoading(false);
                                                if (err?.response?.status === 401) {
                                                    toast.error(err.response?.data?.error, toastOptions);
                                                }
                                            })
                                    }
                                    }
                                >
                                    {t('Delete')}
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default SelectedSkill;
