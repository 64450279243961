import Pusher from 'pusher-js';

const pusherKey = '95dcfede34a6f0505053'; // Replace with your actual Pusher key
const pusherCluster = 'ap2'; // Replace with your actual cluster

let pusherInstance = null;

export const initializePusher = (userId, type) => {
    if (!pusherInstance) {
        pusherInstance = new Pusher(pusherKey, {
            cluster: pusherCluster,
        });

        // Subscribe to user-specific and global channels
        const userChannel = pusherInstance.subscribe(`user-${userId}`);
        const globalChannel = pusherInstance.subscribe('push-channel');
        let chatChannel = null;
        if(type === 'CHAT'){
            chatChannel = pusherInstance.subscribe(`provider-chat-${userId}`);
        }

        return { userChannel, globalChannel, chatChannel };
    }
    return { userChannel: null, globalChannel: null, chatChannel: null };
};

export const getPusherInstance = () => pusherInstance;

export const disconnectPusher = () => {
    if (pusherInstance) {
        pusherInstance.disconnect();
        pusherInstance = null;
    }
};
