import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import {useTranslation} from "react-i18next";

import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getUserNotifications} from "../../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import Pusher from 'pusher-js';
// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // useEffect(() => {
    //     getAlerts();
    //
    //     const interval = setInterval(() => getAlerts(), 10000)
    //     return () => {
    //         clearInterval(interval);
    //     }
    // }, []);
    //
    // const getAlerts = () => {
    //     var user_id = JSON.parse(localStorage.getItem('auth_user'))?._id
    //     if(user_id){
    //         dispatch(getUserNotifications({user_id: user_id}));
    //     }
    //     else{
    //         navigate('/logout')
    //     }
    //
    // }

    useEffect(() => {
        const user_id = JSON.parse(localStorage.getItem('auth_user'))?._id;

        if (user_id) {
            dispatch(getUserNotifications({ user_id }));

            // Initialize Pusher only if not already initialized

        } else {
            navigate('/logout');
        }
    }, [dispatch, navigate]);

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    const { t, i18n } = useTranslation();

    return (
        <>
            <List sx={{paddingTop: 0}}
                subheader={
                    item.title && item.caption &&(
                        // <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                        //     {/*{i18n.language==='ar'?item.title_ar:item.title_en}*/}
                        //
                        // </Typography>
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
