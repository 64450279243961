import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from "react-i18next";

const currencies = [
    {
        title: 'lorem',
        _id: 'lorem'
    },
    {
        title: 'lorem',
        _id: 'lorem'
    }
];

const Select = ({ lable, type, name, onChange, data, options, size, onBlur, error }) => {
    const itemOptions = options ? options : currencies;
    const { t, i18n } = useTranslation();

    return (
        <>
            <Typography
                gutterBottom
                sx={{
                    fontSize: '1rem',
                    fontWeight: 200,
                    color: 'black'
                }}
            >
                {lable}
            </Typography>
            <TextField
                fullWidth
                type={type}
                select
                value={data}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                size={size ? size : 'small'}
                error={error}
            >
                {itemOptions.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                        {i18n.language==='ar'?option.title_ar:option.title_en}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export default Select;
