import {initializeApp}  from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDCrXKWA9rXaAJ0GIwdnMHRCjS-VJvoNoc",
    authDomain: "omram-communication.firebaseapp.com",
    projectId: "omram-communication",
    storageBucket: "omram-communication.appspot.com",
    messagingSenderId: "210100008579",
    appId: "1:210100008579:web:e591fc2d5718d0c18f0db6",
    measurementId: "G-NM3DTTQP40"
};

// const app = initializeApp(firebaseConfig);
//
// const messaging = getMessaging();
//
// export const getTokens = async (setTokenFound) => {
// //VAPID_KEY is the Web push certificates key pair
//     return getToken(messaging, {vapidKey: 'BJAZpkBvf10K-pa4QL7FhWtMhFOulaMCkRVPyPog5TgsdxqpFl2ASl_SJdOdIyiMQiuP2sDoalbUEccekbu_00A' }).then((currentToken) => {
//         if (currentToken) {
//             setTokenFound(true);
//             localStorage.setItem('fcm',currentToken)
//             // Track the token -> client mapping, by sending to backend server
//             // show on the UI that permission is secured
//         } else {
//             setTokenFound(false);
//             // shows on the UI that permission is required
//         }
//     }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//         // catch error while creating client token
//     });
// }


let app;
let analytics;
let messaging;

export function initializeFirebase() {
    if (!app) {
        app = initializeApp(firebaseConfig);

        try {
            messaging = getMessaging(app);

            onMessage(messaging, (payload) => {
                console.log('Message received. ', payload);
            });

            getToken(messaging, { vapidKey: 'BJAZpkBvf10K-pa4QL7FhWtMhFOulaMCkRVPyPog5TgsdxqpFl2ASl_SJdOdIyiMQiuP2sDoalbUEccekbu_00A' })
                .then((currentToken) => {
                    if (currentToken) {
                        localStorage.setItem('fcm', currentToken);
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        } catch (e) {
            console.log('Messaging error:', e);
        }
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });