import React from 'react';
import {Outlet, Navigate, useSearchParams, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {login} from "../api/api";
import {toast} from "react-toastify";

const isAuth = () => {
    const parse = JSON.parse(localStorage.getItem('auth_user_type'));
    const data = parse?.type;

    return data;
};

const ClientProtectedRoute = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    let adminCompanyId = searchParams.get('company_id')
    if(adminCompanyId){
        const lang = localStorage.getItem('i18nextLng');
        localStorage.clear();
        localStorage.setItem('i18nextLng', lang);
        let values = {
            company_id: adminCompanyId,
            device_type: process.env.REACT_APP_DEVICE_TYPE,//'W',
            firebase_token: localStorage.getItem('fcm'),
            device_id: localStorage.getItem('fcm'),
            latitude: localStorage.getItem('lat'),
            longitude: localStorage.getItem('lng'),
            social_id: null,
            login_type: process.env.REACT_APP_LOGIN_TYPE
        }

        login(values)
            .then((res) => {

                let response = res.data;
                if(response.code===1) {
                    // toast.success(i18n.language === 'ar' ? res.data.ar_message : res.data.en_message);

                    if(response.data){

                        localStorage.setItem('auth_user',JSON.stringify(res.data.data));
                        localStorage.setItem(
                            'token',
                            JSON.stringify({
                                token: res.data.token
                            })
                        );
                        localStorage.setItem(
                            'auth_user_type',
                            JSON.stringify({
                                type: res.data.data.user_type
                            })
                        );

                        if (response.data.register_status === process.env.REACT_APP_REGISTER_OTP) {
                            navigate('/register');
                        } else if (response.data.register_status === process.env.REACT_APP_REGISTER_COMPANY) {
                            navigate('/register');
                        } else if (response.data.register_status === process.env.REACT_APP_REGISTER_PURCHASE) {
                            if(searchParams.get('tap_id')){
                                navigate('/register?tap_id='+searchParams.get('tap_id'));
                            }
                            else{
                                navigate('/register');
                            }

                        } else {
                            navigate('/dashboard');
                        }
                    }
                }
                else{
                    navigate('/logout')
                    toast.error(i18n.language === 'ar' ? res.data.ar_message : res.data.en_message);
                }
            })
            .catch((err) => {
                if (err?.response?.status === 200) {
                    navigate('/logout')
                    toast.error(err.response?.data?.en_message);
                }
            });
    }
    const inCheck = isAuth();

    return inCheck === 'PROVIDER' ? <Outlet /> : <Navigate to="/login" />;
};

export { ClientProtectedRoute, isAuth };
