// reducers.js
const initialState = {
    loading: false,
    notification_count_arr: [],
    notification_arr: [],
    // Add other initial state properties as needed
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_HOME_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_NOTIFICATION_COUNT':
            return { ...state, notification_count_arr: action.payload };
        case 'SET_NOTIFICATION':
            return { ...state, notification_arr: action.payload };
        // Add cases for other actions as needed
        default:
            return state;
    }
};

export default notificationReducer;
