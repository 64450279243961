// reducers.js
const initialState = {
    loading: true,
    total_count: 0,
    buttonLoading: false,
    data: [],
    // Add other initial state properties as needed
};

const inquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORDER_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_BUTTON_LOADING':
            return { ...state, buttonLoading: action.payload };
        case 'SET_ORDER':
            return { ...state, data: action.payload };
        case 'SET_TOTAL_COUNT':
            return { ...state, total_count: action.payload };
        // Add cases for other actions as needed
        default:
            return state;
    }
};

export default inquiryReducer;
