import React, {useEffect, useState} from 'react';
import {Grid, Typography, Container, Chip, Box} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {getAppSettings, getCategory} from "../../api/api";

const PrivacyPolicy = () => {
    let navigate = useNavigate();

    const [Setting, setSetting] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getAppSettings().then((res) => {
            const response = res.data.data;
            setSetting(response);
            setLoading(false);


            localStorage.setItem('settings',JSON.stringify(response))
        });
    }, []);


    return (
        <Box mr={4}>
            {
                !isLoading?(
                    <div dangerouslySetInnerHTML={{
                        __html: ''
                    }}/>
                ) : (
                    <Grid></Grid>
                )
            }
        </Box>
    );
};

export default PrivacyPolicy;
