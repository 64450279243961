import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import en from './en.json';
import ar from './ar.json';

i18n.use(detector).use(initReactI18next).
    init({
    // lng: 'en',
    fallbackLng: 'ar',
    resources: {
        en: en,
        ar: ar,
    },
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

if(i18n.language === 'en-US'){
    i18n.changeLanguage('ar')
}


if(i18n.language==='ar') {
    document.dir = 'rtl'
    document.body.setAttribute("dir", "rtl");
    document.querySelector("html").setAttribute("lang", 'ar');
}
else{
    document.dir = 'ltr'
    document.body.setAttribute("dir", "ltr");
    document.querySelector("html").setAttribute("lang", 'en')
}

export default i18n;
