import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

const Test = () => {
    const { t, i18n } = useTranslation();



    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12}>
                <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                    <CardContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item lg={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Contact Us')}</Typography>
                                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Contact Us')}</Typography>
                                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Contact Us')}</Typography>
                                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Contact Us')}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Test;
